import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import noScroll from "no-scroll"

import ServicesTemplate from "templates/ServicesTemplate"

import Form from "components/Form/Form"
import ContactInformation from "components/ContactInformation/ContactInformation"
import FormModal from "components/FormModal/FormModal"

const StyledContactContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: 1600px) {
    justify-content: space-evenly;
  }
`

const StyledFormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 350px;
    margin-right: 16px;
  }
`
const StyledPartTitle = styled.h2`
  color: ${({ theme }) => theme.colors.contact.contactTitle};
  font-family: "Roboto";
  font-size: ${({ theme }) => theme.fontSize.xxs};
`

const StyledContactInfoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }

  @media (min-width: 768px) {
    max-width: 350px;
  }
`

function Contact() {
  const intl = useIntl()
  const contactInfo = [
    {
      title: intl.formatMessage({ id: "contact.contactInfo.0.title" }),
      content: intl.formatMessage({ id: "contact.contactInfo.0.content" }),
    },
    {
      title: intl.formatMessage({ id: "contact.contactInfo.1.title" }),
      content: intl.formatMessage({ id: "contact.contactInfo.1.content" }),
    },
    {
      title: intl.formatMessage({ id: "contact.contactInfo.2.title" }),
      content: intl.formatMessage({ id: "contact.contactInfo.2.content" }),
    },
  ]

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (showModal) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }, [showModal])

  return (
    <ServicesTemplate titleID="contact.mainTitle">
      <>
        <StyledContactContainer>
          <StyledFormContainer>
            <StyledPartTitle>
              <FormattedMessage id="contact.formTitle" />
            </StyledPartTitle>
            <Form showModal={showModal} setShowModal={setShowModal} />
          </StyledFormContainer>
          <StyledContactInfoContainer>
            <StyledPartTitle>
              <FormattedMessage id="contact.contactInfoTitle" />
            </StyledPartTitle>
            <ContactInformation contactInfo={contactInfo} />
          </StyledContactInfoContainer>
        </StyledContactContainer>
        <FormModal showModal={showModal} />
      </>
    </ServicesTemplate>
  )
}

export default Contact
